@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.sideMenu {
  display: flex;
  flex-direction: column;
  min-width: 306px;
  max-width: 306px;

  @include tablets {
    max-width: 100%;
  }
}
