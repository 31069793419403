@import '../../../styles/utils/mixins';

.toastsContainer {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 10px;
  width: 100%;
  z-index: 3;

  @include phones {
    top: 0;
    margin-top: 0;
  }
}
