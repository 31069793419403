.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &Text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #A0A0A0;
    padding-left: 10px;
  }
}
