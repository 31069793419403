@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.headerWrapper {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  height: $header-height;
  max-height: $header-height;
  min-height: $header-height;
  background: $base-bg-color;
  
  @include tablets {
    position: fixed;
    height: $mobile-height;
    max-height: $mobile-height;
    min-height: $mobile-height;
  }

  .headerWrapperContainer {
    width: 100%;
  }
}
