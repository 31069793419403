@import '../../../../../../../styles/utils/variables';

.popupItem {
  display: flex;
  flex-direction: row;

  cursor: pointer;

  & > img {
    height: 42px;
    width: 42px;
        
    border-radius: 50%;
  }

  &Content {    
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;

    margin-left: 16px;
  }

  &TitleWrapper {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: calc(1 * 16px);
  }

  &Title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $btn-text-color;
  }

  &InfoWrapper {
    display: flex;
    flex-direction: row;
    align-items: end;
  }

  &InfoCost {
    margin-left: 16px;
  }
}