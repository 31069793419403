@import '../../../styles/utils/variables';

.dropFileInput {
  background: $base-bg-color;
  border: 1px dashed $border-color;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 20px;
  color: #686868;
  cursor: pointer;

  &Wrapper {
    width: 100%;
  }

  &Accept {
    background-color: #F5F5F5;;
  }

  &Error {
    border-color: $primary-color;
    background-color: #F5F5F5;;
  }

  &Accent {
    margin-left: 8px;
    font-weight: 500;
    color: $primary-color;
  }
}
