@import '../../../styles/utils/variables';


$class-name-base: 'fileLoader';
$color-text-default: #212121;
$font-size-small: 12px;
$font-size-base: 16px;
$font-size-big: 20px;

$line-height-base: 24px;
$line-height-big: 32px;
$color-bg-default: #BDBDBD;

.#{$class-name-base}Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.#{$class-name-base} {
  width: 100%;
  background: #FFFFFF;
  color: $color-text-default;

  &__label,
  &__sublabel {
    display: block;
  }

  &__label {
    font-size: $font-size-big;
    font-weight: $font-medium;
    line-height: $line-height-big;
    margin-bottom: 36px;
  }

  &__sublabel {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: $font-size-small;
    color: $color-bg-default;
  }

  &_not_valid {
    .#{$class-name-base}Container {
      border: 1px dashed $color-danger;
      border-radius: 5px;
    }
  }

  &_with_files {
    .#{$class-name-base}Container {
      border-color: transparent;
    }
  }
}

.#{$class-name-base}Button {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &Message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $color-text-gray;
  }

  input {
    display: none;
  }
}


.#{$class-name-base}ValidationInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #A0A0A0;
}

.#{$class-name-base}Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  background-color: #F7F7F7;
  border: 1px dashed rgba(160, 160, 160, 1);
  border-radius: 10px;
  padding: 24px;
  user-select: none;
  transition: all 0.2s;
}

.#{$class-name-base}Files {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.#{$class-name-base}File {
  display: flex;
  align-items: flex-start;
  height: 40px;
  position: relative;
  transition: all .2s ease-in;
  gap: 16px;
  background-color: transparent;

  &__body {
    flex-grow: 1;
    overflow: hidden;
  }
  
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $text-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }

  &__info {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #AAABAD;
  }
}

.#{$class-name-base}ErrorMessage {
  text-align: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: $primary-color;
}
