$app: 'rc'; // prefix means ranepa courses

// -----------------------------------------
// Fonts
// -----------------------------------------

$font-family-1: 'ALSHauss', 'Helvetica', 'Arial', sans-serif;
$font-family-2: 'Material Icons';

$font-regular: 400;
$font-medium: 500;
$font-bold: 600;

// -----------------------------------------
// Colors
// -----------------------------------------

$color-text-dark:                           #202020;
$color-text-base:                           #1c1c1c;
$color-text-neutral:                        #888888;
$color-disabled:                            #b9bed1;
$color-text-gray:#686868;

$color-gray-e2:                             #e5e5e5;
$color-gray-f0:                             #f0f2f7;

$color-default:                             #006d5c;
$color-default-dark:                        #005446;

$color-warning:                             #fecb2f;
$color-warning-dark:                        #eaba28;

$color-danger:                              #ed2939;
$color-danger-dark:                         #da1f2e;

// -----------------------------------------
// Sizes
// -----------------------------------------

$field-height: 54px;
$template-editor-headers-height: 52px;


// -----------------------------------------
// Text sizes
// -----------------------------------------

$font-size-lg: 22px;
$font-size-big: 16px;
$font-size-base: 16px;
$font-size-medium: 14px;
$font-size-small: 12px;
$font-size-xs: 10px;

$line-height-base: 24px;
$line-height-big: 20px;
$line-height-small: 18px;


// -----------------------------------------
// Title sizes
// -----------------------------------------

$font-size-title-big: 24px;
$font-size-title-middle: 20px;
$font-size-title-small: 18px;

$line-height-title-big: 32px;
$line-height-title-middle: 28px;
$line-height-title-small: 26px;


// -----------------------------------------
// For margin/padding helper classes
// -----------------------------------------

$spaceamounts: (0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24);
$sides: (top, bottom, left, right);

$page-margin: 64px;
$page-margin-m: 32px;
$page-margin-s: 16px;

$base-plate-height: 130px;
$aux-plate-height: 80px;

// ranepa
$text-black: #000000;
$text-white: #FFFFFF;
$text-brown: #271919;
$btn-text-color: #252525;
$btn-disabled-text-color: #AEAEAE;
$primary-color: #C30341;
$primary-dark-color: #A30236;
$primary-academy-color: #930331;
$light-gray-color: #F7F7F7;
$gray-color: #DADADA;
$green: #60B56E;
$another-green: #49873F;
$green-text-color: #20884A;
$btn-green: #61B453;
$btn-green-hover: #49873F;

$container-max-width-big: 1284px;
$base-bg-color: #FFFFFF;
$light-bg-color: #FFEFEB;
$gray-bg-color: #F5F5F5;
$border-color: #D7D7D7;
$border-hover-color: #AEAEAE;
$another-gray: #777777;

$light-cherry: #DA6289; // Фирменный вишнёвый «Академия»/ Светло-вишнёвый
$light-cherry-bg: #FCF0F4; // Фирменный вишнёвый «Академия»/ Фоновый вишнёвый

$header-height: 100px;
$mobile-height: 60px;