@import '../../../../styles/utils/mixins';

.buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    @include tablets {
        flex-wrap: wrap;
        gap: 8px;
    }
}
