@import '../../../styles/utils/variables';

.sideMenuItem {
  padding: 20px 0;
  border-bottom: 1px solid $border-color;

  &Link {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $btn-text-color;

    &Active {
      font-weight: 500;
      color: $primary-color;

      & > span {
        color: $primary-color;
      }
    }

    &TextWithIcon {
      margin-left: 14px;
    }
  }

  &Badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 18px;
    height: 18px;
    color: $primary-color;
    border-radius: 50%;
    background-color: rgba(163, 2, 54, 0.1);
    font-size: 10px;
  }
}
