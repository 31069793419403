@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.wrapper {
    display: block;
    width: 100%;
    position: absolute;
    top: $header-height;
    left: 0;
    z-index: 1;

    @include tablets {
        top: $mobile-height;
    }
}
