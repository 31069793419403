@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  column-gap: 20px;

  &Logo {
    display: block;
    width: 100px;
    height: 40px;
    flex: 1 0 auto;
  }

  &Profile {
    display: block;
    padding: 8px 14px 0;
    background-color: $base-bg-color;
  }

  @include desktop {
    display: none;
  }
}
