@import '../../../../../styles/utils/variables';

.headerProfileMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1 0 auto;
}
