@import '../../../../../../../styles/utils/variables';
@import '../../../../../../../styles/utils/mixins';

.profileSubmenu {
  min-width: 262px;
  border-radius: 16px;
  background-color: $base-bg-color;
  filter: drop-shadow(0px 32px 64px rgba(9, 23, 57, 0.16));

  @include tablets {
    border-radius: 0;
    filter: none;
  }

  &SettingsWrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &Settings {

    &Title {
      font-size: 14px;
      line-height: 16px;
      color: $another-gray;
    }

    &LinksWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &Link {
      display: flex;
      column-gap: 20px;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }

      &Icon {
        font-variation-settings: 'FILL' 1;

        .profileSubmenuSettingsLink:hover & {
          color: inherit;
        }
      }

      &Title {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $text-black;

        .profileSubmenuSettingsLink:hover & {
          color: inherit;
        }
      }

      &Description {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;
        color: $another-gray;
      }
    }

  }

  &FooterWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding: 16px;
    border-top: 1px solid $border-color;

    &Title {
      font-size: 16px;
      line-height: 16px;
      color: $another-gray;

      .profileSubmenuSettingsLink:hover & {
        color: inherit;
      }
    }
  }
}
