@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.headerNavMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  gap: 20px;

  @include tablets {
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 20px 0;
  }

  &Item {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $btn-text-color;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $primary-color;
    }

    &Active {
      color: $primary-color;
      font-weight: 500;
    }
  }
}
