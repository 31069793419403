@keyframes fadeIn {
    0% {
        background:rgba(0,0,0,.0);
    }
    100% {
        background:rgba(0,0,0,.15);
    }
}
@keyframes fadeOut {
    0% {
        background:rgba(0,0,0,.15);
    }
    100% {
        background:rgba(0,0,0,.0);
    }
}

@keyframes roadRunnerIn {
    0% {
      transform:translate(-200%, -50%) skewX(30deg) scaleX(1.3);
    }
    70% {
      transform:translate(-25%, -50%) skewX(0deg) scaleX(.9);
    }
    100% {
      transform:translate(-50%, -50%) skewX(0deg) scaleX(1);
    }
  }
  
@keyframes roadRunnerOut {
    0% {
        transform:translateX(0px) skewX(0deg) scaleX(1);
    }
    30% {
        transform:translateX(-30px) skewX(-5deg) scaleX(.9);
    }
    100% {
        transform:translateX(1500px) skewX(30deg) scaleX(1.3);
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        background:rgba(0,0,0,.15);
    }
    100% {
        background:rgba(0,0,0,.0);
    }
}
