.cardTextWithIcon {
  display: flex;
  column-gap: 6px;

  &Icon {
    vertical-align: middle;
    color: #A30236;
  }

  &Text {
    max-width: 92px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #686868;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
