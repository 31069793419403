@import '../../../styles/utils/variables';

.sideSubMenu {
  display: block;
  width: 100%;
  background: #FFFFFF;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $btn-text-color;
    cursor: pointer;
    width: 100%;

    &Text {
      display: flex;
      align-items: center;
    }

    &Opened {
      border-bottom: 1px solid $border-color;
      padding-bottom: 15px;
    }

    &Active {
      font-weight: 500;
      color: $primary-color;
    }
  }

  &Icon {
    margin-right: 13px;
    color: $btn-text-color;

    &Active {
      color: $primary-color;
    }
  }

  &Items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    padding: 25px 30px 0;
  }

  &Item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $btn-text-color;

    &Active {
      font-weight: 500;
      color: $primary-color;
    }
  }
}
