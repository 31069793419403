@import '../../styles/utils/mixins';

.#{$app}-typ {
  &-heading-40 {
    font-style: normal;
    font-weight: $font-medium;
    font-size: 40px;
    line-height: 53px;

    color: $text-black;
  }

  &-heading-32 {
    font-style: normal;
    font-weight: $font-medium;
    font-size: 32px;
    line-height: 42px;
    color: $btn-text-color;

    @include tablets {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-heading-24 {
    font-style: normal;
    font-weight: $font-medium;
    font-size: 24px;
    line-height: 31px;
    color: $btn-text-color;
  }

  &-button-text-14 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    /* Черный */

    color: $btn-text-color;
  }
}
