@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.container {
  background: $primary-color !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  align-items: center !important;

  @include desktop {
    padding: 23px 69px;
  }
}

.content {
  max-width: 600px;
}

.allowBtn {
  background-color: $base-bg-color !important;
  padding: 15px 30px 12px !important;
  color: $primary-color !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 10px !important;
  font-weight: 500;
}
