@import '../../../styles/utils/variables';

.fileInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 13px 16px;
  font-size: 12px;

  &Data {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &Name {
      font-weight: 500;
      line-height: 15px;
      color: $text-black;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &Meta {
      line-height: 15px;
      color: #aaabad;
    }
  }
}
