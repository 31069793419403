@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.notification {
  display: inline-block;
  width: auto;
  min-width: 250px;
  background-color: $color-default;
  color: $base-bg-color;
  text-align: left;
  padding: 16px 32px 16px 16px;
  position: absolute;
  z-index: 10;
  font-size: $font-size-big;
  transition: all .3s;
  margin: 8px;
  border-radius: 8px;

  @include phones {
    margin: 0;
    border-radius: 0;
    padding: 32px;
    width: 100%;
  }

  &_error {
    background-color: $color-danger-dark;
  }

  &_info {
    background-color: $color-warning;
  }

  &_init {
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
  }

  &_closing {
    opacity: 0;
    left: -100px;
  }

  &Close {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    color: white;

    @include phones {
      right: 4px;
      top: 4px;
    }
  }
}
