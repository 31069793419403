@import '../../../../../../../styles/utils/variables';
@import '../../../../../../../styles/utils/mixins';

.userProfile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    &Data {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > p:nth-child(1) {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $btn-text-color;
            width: max-content;
        }

        & > p:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #A0A0A0;
        }

        @include tablets {
            display: none;
        }
    }

    &Image {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        @include tablets {
            width: 32px;
            height: 32px;
        }
    }
}
