@import '../../../styles/utils/mixins';

.manyColumnsBox {
  display: grid;

  @include tablets {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
