@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 10px;
  font-weight: $font-medium;
  cursor: pointer;
  box-sizing: border-box;
  transition: transform ease-out 0.4s;

  &:active {
    transform: scale(0.9);
  }

  &_primary {
    color: $text-white;
    background: $primary-color;
  }

  &_secondary {
    color: $primary-color;
    background-color: $base-bg-color;
    border: 1px solid $border-color;

    &:hover {
      border: 1px solid $border-hover-color;
    }
  }

  &_transparent {
    color: $text-white;
    background-color: transparent;
    border: 1px solid $base-bg-color;
  }

  &_btnlink {
    color: $primary-color;
    background: $base-bg-color;
  }

  &_small {
    font-size: 12px;
    padding: 0 12px;
    height: 30px;
  }

  &_medium {
    font-size: 16px;
    padding: 0 16px;
    height: 48px;
  }

  &_disabled, &_disabled:hover {
    cursor: default;
    color: $btn-disabled-text-color;
    background: $gray-bg-color;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    border: 0;
  }

  &_selected {
    background: $btn-green;
    color: $text-white;
    border: 0;

    &:hover {
      background-color: $btn-green-hover;
      border: 0;
    }
  }

  &__icon {
    font-family: $font-family-2;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    margin: 0 8px 2px 0;

    &:hover {
      opacity: 0.9;
    }

    &_small {
      font-size: 14px;
    }

    &_medium {
      font-size: 24px;
    }

    &_no_margins {
      margin: 0;
    }

    &_disabled {
      color: #cccccc;
    }
  }

  &_noninteractable {
    background: transparent;
  }

  &_responsive {
    @include tablets {
      width: 100%;
    }
  }
}
