@import '../../../../../../../styles/utils/variables';

.userProfileMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  //cursor: pointer;
  position: relative;
  height: 1px; // hack for submenu
  padding-left: 8px;

  &Dropdown {
    margin-top: 8px;
  }
}
