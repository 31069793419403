@import '../../../styles/utils/variables';

.formikInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;

  &CheckboxWrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &Checkbox {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: $base-bg-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    position: relative;

    &:checked::after {
      content: '';
      position: absolute;
      transform: rotate(-45deg);
      top: 4px;
      left: 4px;
      width: 12px;
      height: 8px;
      border: 2px solid $primary-color;
      border-top-style: none;
      border-right-style: none;
    }
  }

  &Label {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #686868;
  }

  &Input {
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 20px;
    width: 100%;

    &::placeholder {
      color: $border-color;
    }

    &:disabled {
      color: #a0a0a0;
    }
  }

  &Error {
    font-size: 16px;
    line-height: 20px;
    color: $primary-color;
  }
}
