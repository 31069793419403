@font-face {
  font-family: 'ALSHauss';
  src: local('ALSHauss-Thin'),
  url('~@assets/fonts/ALSHauss-Thin.woff') format('woff'),
  url('~@assets/fonts/ALSHauss-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'ALSHauss';
  src: local('ALSHauss-Light'),
  url('~@assets/fonts/ALSHauss-Light.woff') format('woff'),
  url('~@assets/fonts/ALSHauss-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'ALSHauss';
  src: local('ALSHauss-Regular'),
  url('~@assets/fonts/als_hauss_regular.woff2') format('woff2'),
  url('~@assets/fonts/als_hauss_regular.woff') format('woff');
  //url('~@assets/fonts/ALSHauss-Regular.woff') format('woff'),
  //url('~@assets/fonts/ALSHauss-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'ALSHauss';
  src: local('ALSHauss-Medium'),
  url('~@assets/fonts/als_hauss_medium.woff2') format('woff2'),
  url('~@assets/fonts/als_hauss_medium.woff') format('woff');
  //url('~@assets/fonts/ALSHauss-Medium.woff') format('woff'),
  //url('~@assets/fonts/ALSHauss-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'ALSHauss';
  src: local('ALSHauss-Bold'),
  url('~@assets/fonts/als_hauss_bold.woff2') format('woff2'),
  url('~@assets/fonts/als_hauss_bold.woff') format('woff');
  //url('~@assets/fonts/ALSHauss-Bold.woff') format('woff'),
  //url('~@assets/fonts/ALSHauss-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('~@assets/fonts/MaterialIconsOutlined-Regular.otf') format('opentype'),
  url('~@assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
  url('~@assets/fonts/MaterialIcons-Regular.woff') format('woff'),
  url('~@assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
  font-display: 'block';
}

//@font-face {
//  font-family: 'Material Symbols Outlined';
//  font-style: normal;
//  font-weight: 400;
//  src: url('~@assets/fonts/Material-Icons.woff2') format('woff2');
//}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('~@assets/fonts/MaterialSymbolsOutlined[FILL,GRAD,opsz,wght].woff2') format('woff2');
  font-display: 'block';
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
