@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.footer {
    display: block;
    width: 100%;
    background: $primary-color;
    padding: 132px 80px 100px 80px;
    margin-top: 120px;
    color: $base-bg-color;

    @include tablets {
        margin-top: 48px;
        padding: 32px 0 32px 0;
    }
}
