html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font: $font-regular $font-size-base $font-family-1;
  color: $color-text-base;
}

html,
body {
  height: 100%;
}
