@import '../../../styles/utils/variables';

.loader {
  position: relative;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  display: inline-block;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &__svg {
    animation: loader-rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__circle {
    stroke-dasharray: 50;
    stroke-dashoffset: 0;
    animation: loader-dash 1.5s ease-in-out infinite, loader-color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke: $primary-color;
  }
}

@keyframes loader-rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}

@keyframes loader-color {
    100%,
    0% {
        stroke: $primary-color;
    }
    40% {
        stroke: $primary-color;
    }
    66% {
        stroke: $primary-color;
    }
    80%,
    90% {
        stroke: $primary-color;
    }
}
