@import './base/reset';

@import './base/fonts';

@import './utils/variables';
@import './utils/mixins';

@import './base/base';

@import './common/margin';
@import './common/typography';
@import './common/animation';

#root {
    font-family: $font-family-1;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    color: #252525;
    background: #FFFFFF;

    .#{$app}-container {
        // overflow-x: hidden;

        @include desktop {
            width: $container-max-width-big;
            margin: 0 auto;
        }

        @include tablets {
            padding-right: 14px;
            padding-left: 14px;
        }

        @include phones {
            padding-right: 14px;
            padding-left: 14px;
        }
    }
}

.#{$app}-material-icons {
    font-family: $font-family-2;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    color: $color-text-neutral;
    @include user-select(none);
    &:hover {
        color: $primary-color;
        cursor: pointer;
    }

    &--disabled {
        color: $color-disabled;
        &:hover {
            color: $color-disabled;
            cursor: auto;
        }
        pointer-events: none;
    }

    /* Rules for sizing the icon. */
    &.md-14 { font-size: 14px; }
    &.md-18 { font-size: 18px; }
    &.md-24 { font-size: 24px; }
    &.md-36 { font-size: 36px; }
    &.md-48 { font-size: 48px; }

    /* Rules for using icons as black on a light background. */
    &.md-dark { color: rgba(0, 0, 0, 0.54); }
    &.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

    /* Rules for using icons as white on a dark background. */
    &.md-light { color: rgba(255, 255, 255, 1); }
    &.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

    &.md-primary { color: $primary-color; }
    &.md-yellow { color: #FFC736; }
    &.md-gray { color: #D9D9D9; }
}

.#{$app}-lock-scroll {
    overflow: hidden !important;
  }
  