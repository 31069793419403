.#{$app}-margin {
  margin: 0;
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .#{$app}-margin__#{str-slice($side, 0, 1)}#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  }
}
