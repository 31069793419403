@import '../../styles/common/animation';
@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.sideMenuWrapper {
  position: fixed;
  top: $mobile-height;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,.0);
  backdrop-filter: blur(5px);
  z-index: 10;
}

.sideMenu {
  position: relative;
  width: 100%;
  background: $base-bg-color;
  animation: fadeInOpacity .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  //border-bottom: 1px solid $border-color;
  box-shadow: 0 1px 0 $border-color;
  overflow-y: auto;
}
