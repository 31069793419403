@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.label {
    @include label;
    margin-bottom: 4px;
}

.error {
    display: block;
    font-size: 12px;
    color: $color-danger;
}
