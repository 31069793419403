@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.footerTerms {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 80px !important;

    @include tablets {
        margin-top: 36px !important;
        flex-direction: column;
        gap: 8px;
    }

    a > p, span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        padding-bottom: 22px;
        color: $base-bg-color;

        @include tablets {
            padding-bottom: 4px;
        }
    }

    &Col {
        flex: 1 0 33.3333%;
    }
}
