@import 'normalize.css/normalize.css';
@import "the-new-css-reset/css/reset.css";

p,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
body,
fieldset,
blockquote {
  margin: 0;
}
