@import '../../../../styles/utils/variables';

.monthCalendar {
  user-select: none;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(7 * 32px);
  background-color: #ffffff;

  &_small {
    width: calc(7 * 28px);
  }

  &__names {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: $color-text-neutral;
    line-height: 1.14;
    font-size: $font-size-base;
    text-transform: uppercase;
    margin-bottom: 16px;

    &_small {
        color: #808080;
        font-size: 8px;
        line-height: 1.5;
        height: 12px;
        text-transform: uppercase;
        margin-bottom: 4px;
    }
  }
}
