@import '../../../../../../../styles/utils/variables';

.popupWindow {
  position: absolute;
  margin-left: -466px;
  z-index: 10;
  max-height: 500px;
  width: 506px;
  padding: 16px;

  background: $base-bg-color;
  border: 1px solid $border-color;
  border-radius: 16px;

  transition: 0.5s ease-in-out;
  transform: scaleY(0);
  transform-origin: 0 0;

  &Visible {
    transform: scaleY(1);
  }

  &ContentWrapper {    
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: calc(500px - 108px);

    overflow-y: auto;
  }

  &FooterWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 4px 0px;
  }

  &FooterLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;    
    line-height: 20px;
    color: $color-text-gray;    
  }

  &FormWrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}