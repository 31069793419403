@import '../../styles/utils/variables';

.back {
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: $color-text-gray;
  text-decoration: none;

  &Icon {
    color: $color-text-gray;
  }
}
