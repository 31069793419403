@import '../../../../styles/utils/variables';

.selectItem {
  padding: 7px 9px;
  cursor: pointer;

  &_empty {
    color: $color-text-neutral;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
  }

  &__subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    font-size: $font-size-base;
    color: $color-text-neutral;
  }

  &:hover {
    background-color: $color-gray-e2;
  }
}
