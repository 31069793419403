@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.wrapper {
  position: relative;
  height: 100%;
  margin: 0 auto;

  @include tablets {
    width: 100%;
    overflow-x: hidden;
  }
}
