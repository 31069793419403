@import '../../../styles/utils/variables';

$defaultPadding: 24px;

.contentBox {
  display: block;
  width: 100%;
  height: 100%;
  padding: $defaultPadding;
  border: 1px solid $border-color;
  border-radius: 20px;

  &_interactable {
    cursor: pointer;

    &:hover {
      border: 1px solid #C9C9C9;
    }
  }
}
