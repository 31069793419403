@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.materialIconsText {
  font-family: $font-family-2;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  color: $color-text-neutral;
  @include user-select(none);

  &:hover {
    color: $primary-color;
    cursor: pointer;
    
    &.md-light {
      color: rgba(255, 255, 255, 0.6);
    }

    &.md-gray2 {
      color: #C9C9C9;
    }
  }

  &_disabled {
    color: $color-disabled;
    pointer-events: none;

    &:hover {
      color: $color-disabled;
      cursor: auto;
    }
  }

  /* Rules for sizing the icon. */
}

.md-14 {
  font-size: 14px;
}

.md-18 {
  font-size: 18px;
}

.md-24 {
  font-size: 24px;
}

.md-36 {
  font-size: 36px;
}

.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.md-light {
  color: rgba(255, 255, 255, 1);
}

.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.md-primary {
  color: $primary-color;
}

.md-yellow {
  color: #FFC736;
}

.md-gray {
  color: #D9D9D9;
}

.md-gray2 {
  color: #C9C9C9;
}

.md-gray2 {
  color: #C9C9C9;
}

.md-green {
  color: $another-green;
}

.md-dark-red {
  color: $primary-academy-color;
}
