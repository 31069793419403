@import '../../../../../../../styles/utils/variables';

.headerSwitcher {
  display: block;
  position: relative;

  &CurrentValue {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $btn-text-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px
    }
  }

  &ListValues {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: $primary-color;
    border-radius: 10px;
    padding: 10px;
    color: $base-bg-color;
    font-size: $font-size-big;
    min-width: 100px;

    &Item {
      cursor: pointer;
      padding: 6px 10px 4px 10px;
      border-radius: 8px;

      &Active {
        background-color: rgba($color: $btn-text-color, $alpha: 0.2);
      }

      &:hover {
        background-color: $green;
      }
    }
  }
}
