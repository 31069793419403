@import '../../../../styles/utils/variables';

.notificationProgress {
    position: relative;
    overflow: hidden;
    height: 4px;

    background-color: $color-disabled;
    opacity: 0.4;
    border-radius: 8px;

    &__bar {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        transition: transform 0.4s linear;
        transform-origin: left;
        background-color: $color-text-neutral;
    }
}
