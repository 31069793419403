@import '../../styles/utils/variables';

.typo {
  font-style: normal;
  font-size: 18px;
  line-height: 130%;

  &__key {
    color: $another-gray;
    font-weight: 500;
  }

  &__value {
    color: #AAABAD;
    font-weight: 400;
  }
}
