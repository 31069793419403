@import '../../../../styles/utils/variables';

.calendarDay {
  display: flex;
  width: 32px;
  height: 32px;
  font-size: $font-size-base;
  line-height: 1.78;
  color: $color-text-base;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;

  &_small {
    width: 16px;
    height: 16px;
    font-size: 10px;
    margin: 0 4px;
  }

  &__prev_month {
    color: $color-text-neutral;
  }

  &__next_month {
    color: $color-text-neutral;
  }

  &__today {
    font-weight: $font-medium;
  }

  &__selected {
    background-color: $primary-color;
    color: #ffffff;
    border-radius: 50px;
  }

  &__weekends {
    background-color: #f5f5f5;
  }

  &__disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &__in_range {
    background-color: $color-gray-e2;
  }

  &__range_start {
    background-color: $primary-color;
    color: #ffffff;
    border-radius: 50px;
    z-index: 1;

    +.calendarDay__in_range {
      box-shadow: $color-gray-e2 -15px 0 0 0;
      z-index: 0;
    }

    +.calendarDay__range_end {
      box-shadow: $color-gray-e2 -15px 0 0 0;
      z-index: 0;
    }
  }

  &__range-start.calendarDay__last {
    +.calendarDay__in_range {
      box-shadow: none;
    }

    +.calendarDay__range_end {
      box-shadow: none;
    }
  }

  &__range_end {
    color: #ffffff;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      height: 100%;
      width: 50%;
      background-color: #dddddd;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -2;
    }

    &::before {
      content: "";
      height: 100%;
      width: 100%;
      border-radius: 50px;
      background-color: $primary-color;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
