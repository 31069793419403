@import '../../../styles/utils/variables';

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &_stretched {
    width: 100%;

    .dropdownInner {
      width: 100%;
    }
  }

  &Inner {
    position: absolute;
    left: 0;
    top: 4px;
    background-color: $base-bg-color;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.13), 0 0 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 99;
    align-items: center;
  }
}
