@import '../../../../styles/utils/variables';

.singleDatepicker {
  display: inline-flex;
  width: calc(7 * 32px + 32px);
  flex-direction: column;

  &__calendar {
    padding: 0 12px;
    width: 100%;
  }

  &__header {
    user-select: none;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 20px 0;
  }

  &__arrow {
    color: $color-text-neutral;
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: $color-text-base;
    }
  }

  &__year {
    white-space: nowrap;
    color: $color-text-base;
    font-weight: $font-medium;
    text-transform: uppercase;
    font-size: $font-size-base;
  }
}
