@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

$baseColor: #3A405B;

.textField {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  &Responsive {
    @include tablets {
      width: 100% !important;
    }
  }

  &Main {
    display: inline-flex;
    width: 100%;
  }

  &BoxBorderRed {
    border: 1px solid $color-danger !important;

    &:hover {
      border: 1px solid $color-danger !important;
    }

    &:focus {
      border: 1px solid $color-danger !important;
    }

    &:active {
      border: 1px solid $color-danger !important;
    }
  }

  &Disabled {
    cursor: default;

    &IconSvg {
      cursor: default;
    }

    &InputDiv {
      background: rgba($baseColor, 0.1);
    }

    &Label {
      color: rgba($baseColor, 0.4);
    }
    &:hover &InputDiv {
      border: 1px solid rgba($baseColor, 0.4);
      cursor: default;
    }
  }

  &IconRight {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    margin-right: 16px;
    user-select: none;
    width: 24px;
    font-size: 20px;
  }

  &ReadOnly {
    cursor: pointer;
  }
}

.textField label {
  position: relative;
  font-weight: $font-medium;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-gray;
  padding-bottom: 8px;
}

.textField div {
  position: relative;
}

.textFieldClearButton {
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }

  & > * {
    pointer-events: none;
  }
}

.textFieldIcon {
  position: absolute;
  top: 1px;
  left: 0;
  padding: 0 8px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: $color-text-base;
  z-index: 2;
}

.textFieldIcon > i {
  font-size: 20px;
  color: $color-default;
}

.textFieldBase {
  font-family: $font-family-1;
  font-size: $font-size-base;
  height: 46px;
  border: 1px solid $border-color;
  border-radius: 8px;
  outline: none;
  min-height: 46px;
  background: #fff;
  color: $color-text-base;

  &:hover {
    border: 1px solid $color-text-neutral;
  }

  &:focus {
    border: 1px solid $color-text-neutral;
  }

  &:active {
    border: 1px solid $color-text-neutral;
  }

  &:disabled {
    background: #eee;
    border: none;
    cursor: default;
    color: rgba($baseColor, 0.4);

    .textFieldIcon .material-icons {
      cursor: default;
    }
  }
}

.textFieldError {
  border: 1px solid $color-danger !important;
}

.textFieldErrorMessage {
  margin-top: 3px;
  font-size: 12px;
  color: $color-danger;
}

.textFieldInput {
  @extend .textFieldBase;
  padding: 15px 20px 12px 12px;
  width: 100%;
  font-size: $font-size-base;

  &Clearable {
    padding-right: 64px;
  }
}

.textFieldIcon + .textFieldInput {
  padding: 7px 28px 7px 30px;
}

.textField input[type=text]::-ms-clear {
  display: none;
}

.textField input::-webkit-input-placeholder {
  color: $color-disabled;
}

.textField input:-ms-input-placeholder {
  color: $color-disabled;
}

.textField input[placeholder] {
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.textField input:-ms-input-placeholder {
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.textFieldRightIcons {
  display: flex;
  position: absolute !important;
  top: 0;
  right: 0;
  height: 100%;
  font-size: 20px;
  color: $color-text-base;
  cursor: pointer;
}

.textFieldNoFocus {
  position: absolute !important;
  padding: 0 28px 0 12px;
  font-size: 14px;
  color: $baseColor;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 64px);
}

.textFieldIcon~.textFieldNoFocus {
  padding: 7px 28px 7px 30px;
}

.textFieldInput:focus {
  color: $baseColor;
}

input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 40rem #ffff inset;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: $baseColor;
  -webkit-box-shadow: 0 0 0 40rem #ffff inset;
}

.textFieldDisclaimer {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $another-gray;
  margin-top: 4px;
}
