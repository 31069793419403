
@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.footerMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    
    @include tablets {
        flex-direction: column;
        gap: 20px;
    }

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        text-transform: uppercase;
        margin-bottom: 40px;

        @include tablets {
            margin-bottom: 0;
        }
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        padding-bottom: 34px;

        @include tablets {
            padding-left: 20px;
            padding-bottom: 0;
        }
    }

    &Programs, &About, &Contacts {
        flex: 1 0 33.3333%;

        @include tablets {
            flex: 1 0 auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex-wrap: wrap;
        }
    }

    &Contacts {
        @include tablets {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }

        &Social {
            display: flex;
            flex-direction: column;
            gap: 34px;
            margin-top: 36px;

            @include tablets {
                width: 100%;
                flex-direction: row;
                align-items: flex-start;
                gap: 12px;
                margin-top: 0;
                flex-wrap: wrap;
            }

            a {
                display: flex;
                gap: 16px;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                color: white;
                text-decoration: none;

                @include tablets {
                    width: fit-content;
                }

                & > span:nth-child(1) {
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 20px;
                }

                [class="youtube"] {
                    background-image: url('../../../../../../assets/images/footer/social-youtube.svg');
                }

                [class="vk"] {
                    background-image: url('../../../../../../assets/images/footer/social-vk.svg');
                }

                [class="tg"] {
                    background-image: url('../../../../../../assets/images/footer/social-tg.svg');
                }
                [class="tiktok"] {
                    background-image: url('../../../../../../assets/images/footer/social-tiktok.svg');
                }
                [class="ok"] {
                    background-image: url('../../../../../../assets/images/footer/social-ok.svg');
                }
                [class="dzen"] {
                    background-image: url('../../../../../../assets/images/footer/social-dzen.svg');
                }
            }
        }
    }
}
