@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.avatarLoader {
  position: relative;
  width: fit-content;

  @include tablets {
    margin: 0 auto;
  }

  &Image {
    width: 197px;
    height: 197px;
    border-radius: 50%;
    object-fit: cover;
    max-block-size: none;
    max-inline-size: none;
  }

  &PhotoIcon {
    position: absolute;
    top: calc(197px - 64px);
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid $border-color;
    background-color: $base-bg-color;
  }
}
