@import '../../../../../../../styles/utils/variables';

.headerDropdown {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 10;
  transition: 0.5s ease-in-out;
  transform: scaleY(0);
  transform-origin: 0 0;

  &Visible {
    transform: scaleY(1);
  }
}
