@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.version {
  &Container {
    position: fixed;
    width: 100%;
    left: 0;
    height: 25px;
    background: $primary-dark-color;
    z-index: 3;

    @include desktop {
      top: 0;
    }

    @include tablets {
      bottom: 0;
      height: 48px;
    }
  }

  &Text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-white;
    text-align: center;
    height: 100%;
  }
}
