@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.footerPromo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px !important;

    @include tablets {
        flex-direction: column;
        margin-top: 32px !important;
        gap: 12px;
        width: 100%;
    }

    &Logo {
        & > span {
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url('../../../../../../assets/images/footer/logo.png');
            height: 100px;
            width: 230px;
        }
    }

    &Partners {
        display: flex;
        gap: 12px;

        @include tablets {
            flex-direction: column;
            width: 100%;

            & > button {
                width: 100% !important;
            }
        }
    }
}
