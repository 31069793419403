@import '../../../../styles/utils/variables';

.iconSvg {
  fill: none;
  user-select: none;

  &__stroke_neutral {
    stroke: $color-text-neutral;
  }

  &__stroke_primary {
    stroke: $primary-color;
  }

  &__stroke_secondary {
    stroke: $color-warning;
  }

  &__stroke_default {
    stroke: $btn-text-color;
  }

  &__fill_primary {
    fill: $primary-color;
  }

  &__fill_secondary {
    fill: $color-warning;
  }

  &__fill_neutral {
    fill: $color-text-neutral;
  }

  &__fill_default {
    fill: $btn-text-color;
  }
}
