/**
*  Mixins for media queries
*  @example
*
*  div {
  *    @include tablets { ... }
  *  }
  */

@import './variables';

@mixin desktop {
  @media screen and (min-width: 980px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 979px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

/**
 * Mixin for placeholder
 */

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }
}

@mixin user-select($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

/**
 * Mixin for interactive elements (links and buttons)
 */

@mixin interactive-states {
  & {
    cursor: pointer;
    transition: all .3s ease-out;
  }
  &:hover {
    opacity: .9;
  }

  &:active {
    transform: scale(.9);
  }
}


@mixin label {
  font-weight: $font-medium;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-gray;
}
