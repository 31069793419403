@import '../../../styles/utils/mixins';

.hbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;

  @include tablets {
    flex-wrap: wrap;
  }

  &_wrap {
    flex-wrap: wrap;
  }
}
