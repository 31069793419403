@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.select {
  display: inline-flex;
  flex-direction: column;

  &_responsive {
    @include tablets {
      width: 100% !important;
    }
  }

  &__list {
    padding: 8px;
    width: 100%;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(29, 54, 83, 0.17);
      border-radius: 5px;
    }
  }
}
