@import '../../../../../../../styles/utils/variables';

.theme {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  &Title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $text-black;
  }

  &Toggle {
    &Switch {
      display: inline-block;
      width: 50px;
      height: 20px;
      position: relative;

    }

    &Input {
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;

      &:checked + .themeToggleSlider {
        background-color: $primary-color;
      }

      &:focus + .themeToggleSlider {
        box-shadow: 0 0 1px $primary-color;
      }

      &:checked + .themeToggleSlider:before {
        transform: translateX(20px);
        background-color: $base-bg-color;
        background-image: url('../../../../../../../../assets/images/icons/sunny-icon.png');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &Slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $border-color;
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        position: absolute;
        content: '';
        height: 30px;
        width: 30px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: 0.4s;
        box-shadow: 0 0 15px #2020203d;
        background-image: url('../../../../../../../../assets/images/icons/night-icon.png');
        background-color: $base-bg-color;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
      }
    }
  }
}
