@import '../../../../../styles/utils/variables';
@import '../../../../../styles/utils/mixins';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  margin-top: 10px;

  &LogoWrapper {
    min-width: 280px;
  }

  &ProfileWrapper {
    min-width: 280px;
  }

  &Logo {
    display: block;
    width: 114px;
    height: 50px;
    flex: 1 0 auto;
  }

  &Profile {
    color: inherit;
  }

  @include tablets {
    display: none;
  }
}
